.transaction-table-tabs {
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-top: 20px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-color: #e2ecec;
  height: 50px;
  column-gap: unset !important;

  .tabs {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    background: #ced4da;
    padding: 15px 7px 10px 15px;
    border-collapse: collapse;
    text-align: center;

    &.selected {
      background: #078888;
      color: white;
    }

    &.inDesktop {
      width: 170px;
    }
  }

  .tabs:hover {
    background: #00c4c4;
    font-weight: bold;
    cursor: pointer;
  }

  button#transaction-more-actions {
    gap: 3px;
  }

  .more-actions-dot {
    width: 4px;
    height: 4px;
    background-color: #292929;
    border-radius: 50%;
    display: inline-block;
    margin: auto;
  }

  .tabs-container {
    display: flex;
    width: 100%;
  }
}
