.customer-support {
  box-shadow: 0 16px 24px -10px rgba(0, 0, 0, 0.05);

  .form-input {
    background: #fcfcfc;
    box-shadow: 2px 9px 6px -2px rgba(0, 0, 0, 0.1);
    appearance: none; /* appearance-none */
    border: 1px solid #ccc; /* border */
    border-radius: 8px;
    width: 100%; /* w-full */
    padding: 0.5rem 0.75rem; /* py-2 px-3 */
    color: #333; /* text-gray-700 */
    line-height: 1.25; /* leading-tight */
  }

  .mat-form-field-flex {
    // todo remove this when cleanup
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #dbdbdb !important;
    box-shadow: inset 2px 2px 4px rgba(88, 102, 132, 0.2) !important;
  }

  .form-input:focus {
    outline: none;
  }

  placeholder {
    display: none;
    width: 32px;
    height: 32px;
  }
}
