.wizard {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  display: flex;
  z-index: 5;
  height: 100%;
  width: 100%;
  background: rgb(238, 238, 238, 0.8);

  .step-card {
    padding: 24px 32px 24px 32px;
    width: 30%;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: -4px -4px 4px 0px #ffffff33, -4px -4px 10px 0px #ffffff, 6px 6px 16px 0px #09534d29;
  }

  @media (max-width: 1024px) {
    .step-card {
      width: 100%;
      min-width: 360px;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 15px;
    margin-bottom: 5px;

    h2 {
      text-align: center;
    }
  }

  .progress-bar-section {
    > div {
      background-image: url("../../../images/icons/wizard/progress-bar.png");
      background-position: top right;
      display: none;
      height: 7px;
      position: absolute;
      z-index: 1;
    }

    .step-2 {
      display: block;
      width: 40%;
    }

    .step-3 {
      display: block;
      width: 85%;
    }
  }

  .step-check-icon-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
