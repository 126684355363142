.modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 5px;
  outline: 0;
}
.modal-title {
  font-size: 20px !important;
}
.button-group {
  margin-left: auto;
  margin-right: 0;
  margin-top: 20px;
}
