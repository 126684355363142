.import {
  &-wrapper {
    display: flex;
    margin-top: 25px;
  }
  &-icon {
    height: 80px;
    width: auto;
    margin-right: 10px;
    border: 1px solid #00c4c4;
    padding: 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.import-container {
  display: flex;
  flex-direction: column;
}

img.import-icon-image {
  height: 85%;
}

span.button-text {
  font-size: 11px;
  margin-top: 3px;
  color: #555555;
  font-weight: bold;
}

img.import-icon-image.link-to-api {
  height: 100%;
  margin-bottom: -9px;
}
