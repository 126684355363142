@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

@config "../../../tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .accent {
    @apply tw-bg-accent tw-text-neutral-800;
  }

  .neutral {
    @apply tw-bg-neutral-50 tw-text-neutral-800;
  }

  .primary {
    @apply tw-bg-primary tw-text-neutral-800;
  }

  .danger {
    @apply tw-bg-neutral-50 tw-text-danger;
  }

  .danger-invert {
    @apply tw-bg-danger tw-text-white;
  }

  .btn-icon {
    @apply tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-shadow-button;
  }

  .input-field {
    @apply tw-h-12 tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-px-6 read-only:!tw-border-0 read-only:!tw-border-neutral-100 read-only:!tw-bg-neutral-50 focus:tw-border-[1.5px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none;
  }

  .input-error {
    @apply tw-mt-3.5 tw-text-xs;
  }

  .input-error-border {
    @apply tw-border-[1.5px] tw-border-solid tw-border-danger;
  }

  .right-round-border {
    @apply tw-rounded-r-[50px] tw-rounded-l-2xl;
  }

  .left-round-border {
    @apply tw-rounded-l-[50px] tw-rounded-r-2xl;
  }

  .tooltip-label {
    @apply tw-whitespace-nowrap tw-font-semibold;
  }

  .panel-dropdown {
    @apply tw-absolute tw-top-11 tw-flex tw-w-full tw-flex-col tw-gap-1 tw-bg-white tw-px-1 tw-shadow-button;
  }

  .round-corner-button {
    @apply tw-h-[40px] tw-w-[112px] tw-rounded-[8px] xs:tw-h-[36px] xs:tw-w-[102px];
  }

  .button-resize {
    @apply tw-w-1/2 xs:tw-w-full;
  }
}
