$background-color: #ffffff; // background color of the form field instead of the input field

.autocomplete-container {
  height: 70px;

  .autocomplete-form-field {
    input[readonly] {
      cursor: default;
    }

    .autocomplete-input {
      padding-left: 20px;
    }
  }

  .autocomplete-form-field {
    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-wrapper {
      padding: 0;
      width: 100%;

      .mat-form-field-flex {
        border-radius: 8px;
        background-color: #f8f8f8;
        display: flex;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 0 0;
        border: 1.5px solid $background-color;
        box-shadow: 2px 2px 4px 0 #58668480 inset;

        .mat-form-field-infix {
          display: flex;
        }

        &:hover,
        &:focus-within {
          border: 1.5px solid var(--Color-Teal-Teal-500, #00c4c4);
          box-shadow: 2px 2px 4px 0px rgba(88, 102, 132, 0.5) inset;
        }
      }
    }
  }

  .mat-datepicker-toggle {
    color: #00c4c4;
  }
}

.mat-form-field-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.mat-form-field.mat-form-field-error .mat-form-field-wrapper .mat-form-field-flex {
  border: 1.5px solid #db1919;
  box-shadow: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none; // todo get specs from designer
}

.mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  border: none !important;
  box-shadow: none !important;
}
