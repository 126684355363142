$banks: cba, nab, anz, wpc, jpm, wfo, ctg, boa, hsbc, scb, boc;

$icon-size: 48px;
$icon-size-mobile: 32px;

$space: 35px;
$space-mobile: 22px;

@mixin bank-icon($size) {
  width: $size;
  height: $size;
}

.bank-icons {
  .bank {
    background-image: url("../../../images/banks.png");
    background-repeat: no-repeat;
    display: inline-block;
    background-size: auto 100%;
  }

  .icon {
    @include bank-icon($icon-size);
  }
}

@mixin bank-positions($size) {
  $index: 0;
  @each $bank in $banks {
    .#{$bank} {
      background-position: -#{$index * $size};
    }
    $index: $index + 1;
  }
}

@mixin spacing($space) {
  @for $i from 0 through 10 {
    .position-#{$i} {
      left: $i * $space;
    }
  }
}

@include bank-positions($icon-size);
@include spacing($space);

@media (max-width: 767px) {
  .bank-icons {
    .icon {
      @include bank-icon($icon-size-mobile);
    }
  }

  @include bank-positions($icon-size-mobile);
  @include spacing($space-mobile);
}
