mat-sidenav.mat-drawer {
  background-color: #00c4c4;
  border-radius: 0 16px 16px 0; // todo move to tailwind
}

.side-menu {
  nav {
    &:hover {
      background-color: #0788883d;
      border-radius: 8px;
      outline: none;
    }

    span {
      font-size: 16px;
    }
  }

  .active-class,
  .active-class:hover,
  .active-class:focus-within {
    background: #f8f8f8;
    border-radius: 8px;
    outline: none;
    color: #292929;
    box-shadow: 0px 4px 8px -2px #0000001a;
  }
}
