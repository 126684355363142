/*

TODO -- Use tailwind style to make it fluid-responsive

*/
div#cabinet-buttons-container {
  height: 44px;
  background: #f5f5f5;
  box-shadow: 2px 2px 4px rgba(6, 46, 43, 0.24), 6px 6px 10px rgba(6, 46, 43, 0.08),
    -2px -4px 12px #ffffff, -2px -2px 2px #ffffff;
  border-radius: 50px;
}

.none-mobile-btn-container {
  width: 192px;
  display: flex;
  position: absolute;
  right: 16px;
  top: 16px;
  justify-content: center;
  align-items: center;
}

.mobile-btn-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: calc(100% - 32px);
}

.custom-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.item-icon {
  margin-left: 11px;
}

.truncate-text {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap-text {
  white-space: normal;
  word-wrap: break-word;
}

.wrap-header-text {
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break only at word boundaries */
  word-break: normal; /* Prevent breaking words at arbitrary points */
  text-align: center; /* Optional: Center the text */
}

/*
// When we add buttons to right-click event (getContext...) it adds a bunch of spans there. We need to hide them.
.redFont span.ag-menu-option-icon, .redFont span.ag-menu-option-shortcut, .redFont span.ag-menu-option-popup-pointer {
  display: none;
}
*/
