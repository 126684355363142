@import "subscription.component";
// todo change that for a local font
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");

html,
body {
  font-size: 14px;
  overscroll-behavior: none;
  margin: 0;
  position: relative;
  font-family: "Montserrat", serif;
  overflow-x: hidden;
}

main {
  display: block;
  height: 100%;
  position: relative;
  z-index: 1;
}

a {
  color: #00c4c4;
  text-decoration: none;
}

a:hover {
  color: #00c4c4;
  text-decoration: underline;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  background: none;
  border: none;
}

ul {
  margin: 0;
  padding-inline-start: 20px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 12px;
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 12px;
  width: 15px;
}

// todo start this maybe removed

body {
  &.full-width:not(.layout_frontend) {
    .container {
      min-width: 980px;
      width: 90%;
    }
  }
}

// Material Design Start
.mat-sidenav-container {
  height: 100vh;
}

.mat-select-arrow-wrapper {
  display: none !important;
}

.mat-menu-item {
  line-height: 38.5px !important;
  height: 38.5px !important;
}

// Material Design End

.container {
  width: 90%;
  max-width: 3500px;
}

.content {
  margin: 16px;
  min-height: 68%;
}

.card {
  min-width: 200px;
}

.page-header,
.secondary-header,
.tabbed-header {
  margin-bottom: 0.5rem;
  padding-bottom: 0.6rem;

  &:not(.text-danger) {
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }
  }
}

.logo {
  content: url("../images/logo-dark@2x.png");
  display: block;
  margin: 0 auto;
  padding: 20px;
  width: 360px;
}

// todo remove these
.page-content {
  margin-top: 20px;
}

h1,
h2,
h3,
h4,
h5 {
  small {
    font-size: 80%;
  }
}

// todo end remove these

.modal-container {
  // m-header instead of modal-header, m-body to prevent bootstrap css from taking effect. rename it when bootstrap is removed
  .m-header {
    // todo remove this for customer support box
    align-items: center;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    height: 70px;
    font-weight: 400;
    justify-content: space-between;
    margin-bottom: 5px;
    text-transform: none;
  }

  .m-body {
    background: #ffffff;
    border-radius: 12px;
  }
}

.no-background-dialog {
  width: 800px; // fixed width for popup forms
}

.no-background-dialog-v2 {
  width: 450px; // fixed width for popups without forms/fields
}

.no-background-dialog,
.no-background-dialog-v2 {
  .mat-dialog-container {
    box-shadow: none;
    background: none;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .no-background-dialog,
  .no-background-dialog-v2 {
    background: #ffffff;
    height: 100%;
    max-width: 100vw !important; // material design inserts max-width of 80vw
  }

  .no-background-dialog-v2 {
    width: 100% !important;
  }

  // todo start remove this after Settings design is updated
  .modal-open .modal {
    background-color: #ffffff;
  }

  .modal-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  // todo end remove this after Settings design is updated
}

.coming-soon {
  @mixin text-gradient($background) {
    font-style: normal;
    background: $background;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  background-image: url(../images/coming_soon_background.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10%;

  div {
    height: 300px;
    margin-bottom: 240px;

    h2 {
      font-weight: 500;
      font-size: 36px;
      line-height: 43px;
      /* identical to box height, or 119% */
      letter-spacing: -2.53727px;
      @include text-gradient((linear-gradient(90deg, #0079c4 0%, #00c4c4 100%), #00c4c4));
    }

    p {
      font-weight: 700;
      font-size: 64px;
      line-height: 125%;
      /* identical to box height, or 80px */
      font-variant: all-small-caps;

      span:first-child {
        /* color/gradient */
        @include text-gradient((linear-gradient(90deg, #0079c4 0%, #00c4c4 100%), #00c4c4));
      }

      span:nth-child(2) {
        @include text-gradient(linear-gradient(90deg, #96dfff 0%, #00c4c4 100%));
        @media (max-width: 639px) {
          margin-left: 50%;
        }
      }
    }

    span:nth-child(3),
    span:nth-child(4) {
      display: block;
    }
  }
}

.dashboard-card .ag-overlay {
  pointer-events: auto;
}

.nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
// AG Grid End

// todo check progress-bar-loader.scss and main-progress-bar.scss why there's two
.progress-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}

.in-progress {
  font-size: 18px;
  color: #f5f5f5;
}

.progress-bar-label {
  position: absolute;
  margin-top: -70px;
}

.gloss-progress-bar {
  height: 10px !important;
  //width: 50% !important;
  background-color: #00c4c4;
}
// todo end check progress-bar-loader.ts and main-progress-bar.ts why there's two

$animation-duration: 1s;
$legs: 8;
$rotation-angle: calc(360deg / $legs);
$animation-delay-step: calc($animation-duration / $legs);
.gloss-spinner {
  color: #00c4c4;
}
.gloss-spinner,
.gloss-spinner div,
.gloss-spinner div:after {
  box-sizing: border-box;
}
.gloss-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    transform-origin: 40px 40px;
    animation: gloss-spinner $animation-duration linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 2.2px;
      left: 38px;
      width: 4px;
      height: 22px;
      border-radius: 50px;
      background: currentColor;
    }
  }
}

@for $i from 1 through $legs {
  .gloss-spinner div:nth-child(#{$i}) {
    transform: rotate(#{($i - 1) * $rotation-angle});
    animation-delay: #{-1 * $animation-duration + ($i - 1) * $animation-delay-step};
  }
}
@keyframes gloss-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
