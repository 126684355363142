.country-container {
  max-width: 350px;
  margin: auto;

  mat-form-field.mat-form-field.country-form-field {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    max-width: 350px;
    height: 40px;
    background: #f8f8f8;
    box-shadow: 2px 9px 6px -2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: auto;

    input.country-input {
      padding-left: 20px;
    }
  }

  .country-form-field {
    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-wrapper {
      padding: 0;
      width: 100%;

      .mat-form-field-flex {
        border-radius: 8px;
        background-color: #f8f8f8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #dbdbdb;
        padding: 0 15px 0 0;

        mat-icon.carrot-down-icon {
          color: #00c4c4;
          font-size: 24px;
        }
      }
    }
  }
}

.country-autocomplete {
  margin-top: 10px;
  max-height: 300px;
  background: #f5f5f5;
  border: 1px solid #dbdbdb;
  border-radius: 8px !important;

  mat-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 20px;
    height: 40px;
    font-size: 14px;
    color: #292d32;
  }
}
