#reference-table-date-selector,
#reference-table-base-selector {
  width: auto;
}
.preview-table-wrapper {
  height: 60vh;
  display: flex;
  flex-direction: column;
  .reference-table-viewport {
    flex-grow: 1;
    height: 100%;
    margin: 0 auto;
    width: 70%;
  }
}
