.plan-container {
  display: flex;
  justify-content: center;
  width: 952px;
  margin: 30px auto 60px;
  grid-gap: 30px;
  min-height: 534px;
}
.plan-list {
  margin: 30px auto 60px;
  width: 300px;
  text-align: center;
  background: #e0e2e4;
}
.plan-label {
  height: 40px;
}
.plan-title {
  flex: 1;
  flex-direction: column;
  color: #ffffff !important;
  background-color: #00c4c4;
  margin: 0 auto;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
  padding: 10px 30px;
}
.plan-description {
  font-size: 16px;
  margin-top: 60px;
  margin-bottom: 70px;
  font-weight: bold;
  height: 20px;
}
.plan-most-popular {
  background-color: #0f100f;
  color: #ffffff !important;
  font-size: 16px;
  padding: 10px 0px;
}
.popular-description {
  margin-top: 20px;
  margin-bottom: 45px;
}
.plan-price {
  font-weight: 700;
  font-size: 3rem;
}
.plan-price-detail {
  font-size: 14px;
  font-weight: bold;
}
.plan-button-container {
  padding-top: 50px;
}
.plan-button {
  font-size: 24px;
}
.plan-trial {
  padding-top: 10px;
  font-size: 10px;
}
