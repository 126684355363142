.ag-root-wrapper {
  border-radius: 0;
}

.ag-header {
  background-color: #f5f5f5;
}

button.table-action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  height: 30px;
  background: #fcfcfc;
  border: 1px solid #a8c7ff;
  border-radius: 8px;
  color: #292929;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

button.table-action-buttons[disabled] {
  opacity: 0.6;
}

.right-space {
  margin-right: 5px;
}

.more-actions-text {
  line-height: 5px;
}

button#transaction-more-actions {
  gap: 3px;
}
.more-actions-dot {
  width: 4px;
  height: 4px;
  background-color: #292929;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
}

.transaction-action-button-icon {
  color: #292929;
}

.tabs-container {
  display: flex;
  width: 100%;
}

.ag-cell-focus {
  border: none !important;
}

.ag-row-selected {
  border: 1px solid #5589e8;
}

/*.ag-theme-quartz ::-webkit-scrollbar-button {
  display: none;
}

!* For WebKit Browsers (Chrome, Safari) *!
.ag-theme-quartz ::-webkit-scrollbar {
  width: 20px; !* Width of the scrollbar *!
}

.ag-theme-quartz ::-webkit-scrollbar-thumb {
  background-color: #737777; !* Color of the scrollbar thumb *!
}

.ag-theme-quartz ::-webkit-scrollbar-thumb:hover {
  background-color: #000; !* Color on hover *!
}

.ag-theme-quartz ::-webkit-scrollbar-track {
  background: #f5f5f5; !* Background of the track *!
}

!* For Firefox *!
.ag-theme-quartz {
  scrollbar-width: thin;
  scrollbar-color: #737777 #f5f5f5;
  scrollbar-gutter: unset;
}*/
