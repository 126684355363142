.dashboard-card {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
}

.more-button {
  position: absolute !important;
  top: 5px;
  right: 10px;
}

.dashboard-card-content {
  text-align: center;
}

.ironfly-primary-graph__filter-bar {
  display: inline-block;
  width: 100%;
}

.ironfly-primary-graph__filter-item {
  padding-right: 50px;
}

.primary-graph-form-inputs {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;

  justify-content: flex-start;
}

.projections-table-container {
  .projection-form-inputs {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    margin: 0 16px;
  }
}

.tooltip-arrow {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #078888; /* Use the same color as the tooltip background */
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.psg-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  .primary-summary-graph {
    .hud-heading {
      font-size: 0.5rem;
    }
    .balance-point {
      cursor: pointer;
    }
    .current-point {
      stroke-dasharray: 1;
    }
    .brush {
      .selection {
        fill: #a8c7ff;
        fill-opacity: 1;
      }
    }
    .brush-handle {
      fill-opacity: 1;
      &.not-including {
        fill: #fff;
      }
    }
  }

  .psg-tooltip {
    box-sizing: border-box;
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    // left: -100000000px;
    padding: 8px 8px 0px 8px;
    text-align: left;
    line-height: 1;
    font-family: sans-serif;
    font-size: 12px;
    color: #333;
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    pointer-events: none;
    z-index: 1;
  }
}
