.mat-form-field {
  width: 100%;
}
.title {
  padding-bottom: 20px;
  font-weight: bold;
}
.mat-dialog-actions {
  display: flex;
  justify-content: space-between;
}
.mat-form-field-flex {
  justify-content: space-between;
}

.flex-column {
  top: 0px !important;
}

.preview-table-wrapper .mat-form-field {
  width: auto;
}

.elem-app-split {
  padding-bottom: 40px;
}
.deleteBtn {
  float: right;
}
