app-scenario-difference-message {
  height: 100%;
}

.scenario-difference-dialog {
  .desktop-mat-dialog-container {
    height: 600px;
    max-height: 650px;
    padding: 50px 110px;
  }

  .mobile-mat-dialog-container {
    height: 85%;
    max-height: 100%;
    padding: 30px;
  }

  mat-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    isolation: isolate;
    width: 90vw;
    max-width: 608px;
    background: #ffffff;
    border-radius: 12px;
    margin: auto;

    h1 {
      .exclamation-mark {
        color: white;
      }
    }
  }
}
