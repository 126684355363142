#dashboard-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    display: none;
  }

  app-select .form-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    height: 48px;
    width: 254px;
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  }

  .form-group.mb-4 {
    margin-bottom: 0 !important;
  }
}

.help-tooltip {
  position: absolute;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 8px -2px #0000001a;
  z-index: 1000;
  min-width: 340px;
  width: 100%;
  top: 50%;
  max-width: 500px;

  &.middle-modal {
    overflow-y: hidden;
  }

  .help-inner-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .tooltip-pointer {
      z-index: 10001;
      position: absolute;
      width: 0;
      height: 0;
      border-width: 16px;
      border-style: solid;
    }
  }

  .tooltip-information {
    padding: 30px 30px;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.004em;

    &.middle-modal {
      color: #a8a8a8;
      font-size: 14px;
      gap: 8px;
    }

    .help-heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 12px;
    }

    .dashboard-help {
      color: #a8a8a8;
      font-size: 14px;
      line-height: 18.9px;

      .help-heading {
        display: grid;
        font-size: 12px;
        width: 208px;
        height: 32px;
        border-radius: 6.98px;
        align-items: center;
        padding-bottom: 0;
        padding-left: 20px;
        font-weight: 400;
        margin: 12px 0;
      }

      &.selected-dashboard {
        color: #292929;

        .help-heading {
          background-color: #f0f0f0;
        }
      }
    }
  }
}

.help-tooltip-mobile {
  border-radius: 12px 12px 0 0;
  max-width: 500px;
  width: 100vw;
  max-height: 100vh;
  top: auto;
  bottom: 0;
  overflow-y: auto;
}

ul.navbar-nav.flex-row.ml-md-auto.d-none.d-md-flex.nav-avatar {
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 8px;
}

.transaction-table-tabs .tabs {
  border-radius: 0 !important;
  background-color: transparent !important;
}

.tab-container.ng-star-inserted {
  display: flex;
  justify-content: center;
  align-items: center;
}

.transaction-table-tabs .tabs.selected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: white !important;
  box-shadow: inset -2px -2px 4px #ffffff, inset 2px 2px 4px rgba(88, 102, 132, 0.5);
  border-radius: 8px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #292929 !important;
}

.inactive-link * {
  color: lightgray;
  cursor: not-allowed;
}

.active {
  * {
    text-decoration: underline;
  }
}

h3.component-header {
  padding: 0;
  margin: 0;
  font-size: 1.7rem;
  line-height: 1.2;
  color: #078888 !important;
  letter-spacing: 0.6px;
  font-weight: bold;
}

button.header-info-button {
  margin-top: -6px;
  right: 6px;
}

.account-action-buttons-container {
  display: flex;
  justify-content: flex-end;

  button {
    color: #00c4c4 !important;
  }
}

#account-table-actions-container {
  border-bottom: none;
  background-color: #e2ecec;
  margin: 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding: 10px;
  z-index: auto;

  button.account-table-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    height: 30px;
    background: #fcfcfc;
    border: 1px solid #a8c7ff;
    border-radius: 8px;
    color: #292929;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  button.account-table-action-button {
    margin-right: 10px;
  }
}

/** No content in the table message*/
span.ag-overlay-no-rows-center {
  font-size: 14px;
  border-bottom: 1px solid #e2ecec;
  padding-bottom: 4px;
}

.section-info-text {
  padding: 0;
  margin: 0;
  text-align: left;
}

p.section-info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.005em;
  color: #8f8f8f;
  text-align: justify;
}

.stand-out-color {
  color: #292929;
  font-weight: bold;
}

.paragraph-start {
  display: inline-block;
  text-indent: 20px;
}

#account-table-actions-container {
  border-bottom: none;
  background-color: #e2ecec;
  margin: 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding: 10px;
  z-index: auto;

  .account-table-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    height: 30px;
    background: #fcfcfc;
    border: 1px solid #a8c7ff;
    border-radius: 8px;
    color: #292929;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-right: 10px;
  }
}
