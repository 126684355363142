.sync-status-container {
  display: flex;
  align-items: center;

  span.status-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    mat-icon.status-icon {
      font-size: 21px;
      text-align: center;
      align-content: center;
    }

    mat-icon.error {
      color: #d33535;
    }

    mat-icon.sync {
      color: #c2c225;
      animation: rotate 2s linear infinite;
    }

    mat-icon.check {
      color: #049004;
    }
  }
}
