.visible-overflow {
  overflow: scroll !important;
}

.panel-dropdown .mat-option .mat-pseudo-checkbox-checked {
  background-color: #00c4c4 !important; /* Change this color to your desired background color */
}

.option-class {
  white-space: normal !important;
  line-height: 1.2em !important;
  padding: 1px 7px !important;
}
