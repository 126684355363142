.subscription-wrapper {
  border: 2px solid;
  background-color: #ffffff;
  color: #696b6c;
  font-size: 15px;
  border-radius: 30px;
  padding: 30px;
}

.subscription-header-wrapper {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.subscription-price {
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 35px;
  color: black;
  font-weight: bold;
}

.subscription-price-detail {
  font-size: 10px;
  font-weight: bold;
}

.subscription-billing-wrapper {
  margin-top: 30px;
  font-size: 10px;
  font-weight: bold;
}

.subscription-billing-detail {
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
}

.billing-amount {
  float: right;
  padding-right: 40px;
}

.change-plan {
  float: right;
  text-decoration: underline;
  cursor: pointer;
}

.billing-history {
  font-size: 14px;
  font-weight: bold;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
}
