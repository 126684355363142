.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height to 100vh to cover the entire window */
  position: fixed; /* Make the spinner position fixed */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; /* Set a high z-index to ensure it appears above other elements */
  background-color: rgba(0, 0, 0, 0.6); /* Add a semi-transparent background */
}

.hover-row:hover {
  background-color: dodgerblue;
  cursor: pointer;
}

.hover-row:hover td {
  color: whitesmoke;
}

.full-length .mat-form-field-flex {
  flex: 1 1 100%;
}

mat-datepicker-toggle.mat-datepicker-toggle {
  position: relative;
  float: right;
  top: -35px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.page-header.d-flex.container.estimate-container {
  padding-top: 7px;
}

.dynamic {
  background-color: #1a3b66;
  padding: 16px 34px;
  box-shadow: 0px 3px 11px 0px rgba(26, 59, 102, 0.75);
  -webkit-box-shadow: 0px 3px 11px 0px rgba(26, 59, 102, 0.75);
  -moz-box-shadow: 0px 3px 11px 0px rgba(26, 59, 102, 0.75);
  border-radius: 4px;

  label {
    color: white !important;
  }
}

.dynamic-color {
  background-color: #1a3b66;
}

.split-c-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a3b66;
  padding: 0 7px;
  margin-top: 1px;
}

.split-name {
  color: whitesmoke;
  margin: 0;
  width: 36%;
}

// todo ask what's this for???
div.active,
div.active:hover {
  border-radius: 7px;
  display: flex;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

div.active span {
  font-weight: bold;
  color: #1a3b66;
}

.mat-list-base {
  width: 300px;
  padding-top: 0 !important;
}

table.mat-table.cdk-table.container {
  width: 700px;
}

.groups {
  margin-right: 2px;
  border-radius: 5px;
  padding: 4px;
  height: fit-content;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
}

.section {
  display: flex;
}

.estimate-card {
  background-color: #00c4c4;
  border-radius: 6px;
}

.grid-tile {
  display: flex;
  flex-direction: column;
}

.group-content {
  width: 700px;
}

.mat-grid-tile-content {
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: baseline !important;
  margin: 4px 16px !important;
  padding: 7px !important;
  border-radius: 8px;
  color: whitesmoke;
  height: auto !important;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
}

.estimate-card-action-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.section-name-span {
  display: inline-block;
  width: 90px;
  margin-right: 3px;
}

div.section p:first-child {
  color: rgba(26, 59, 102, 0.8901960784);
  display: contents;
}
div.section p:nth-child(2) {
  padding-left: 3px;
  color: black;
}

.section {
  display: flex;
  padding: 4px 0;

  p {
    margin: 0;
  }
}

button.mat-list-item.mat-focus-indicator.create-new-estimate-group.btn.btn-sm.btn-outline-primary {
  margin-top: 5px;
}

.estimate-group-card-action-button-container {
  display: flex;
}

.menu-container {
  margin-bottom: 9px;
  border-bottom: 1px solid #8080804d;
  padding-bottom: 15px;
}

.menu-item {
  min-width: 99px;
  padding: 5px 10px;
  border-radius: 6px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #80808047;
}
.menu-item:hover {
  cursor: pointer;
}

.menu-item.selected-item {
  background-color: #1a3b66;
  color: white;
}

.new-accounts-container {
  border: 1px solid #8080807a;
  border-radius: 6px;
  padding: 18px 7px;
  margin: 10px 0;
  box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
}

.row-container-for-new-accounts {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 50px;
  flex-wrap: wrap;
}

.account-option {
  width: 30%;
  display: flex;
  align-items: flex-start;
  position: relative;
}

span.detected-account-name {
  color: black;
  font-weight: bold;
}

.button-container {
  display: flex;
  padding-left: 17px;
}

button.clear-input-button {
  position: absolute;
  top: 24px;
  right: 18px;
}

.top-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.primary-graph-form-inputs mat-datepicker-toggle.mat-datepicker-toggle {
  position: relative;
  top: 0;
  float: none;
  left: -35px;
}

.prevent-text-truncate {
  white-space: normal;
}

.search-input {
  height: 40px;
  width: 99% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-left: 1% !important;
}

button.prevent-text-truncate.mat-menu-item {
  overflow: inherit;
}

.primary-graph-form-inputs .mat-select-value {
  width: auto !important;
  min-width: 96px;
}

.preview-table-wrapper.mat-form-field {
  width: auto !important;
}

.preview-table-content .mat-select-value-text {
  color: white;
  text-transform: uppercase;
}

.preview-table-content .mat-select-value {
  width: auto;
}

.step-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
  padding: 8px;
  width: 150px;
}

.steps-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
}

.next-button {
  float: right;
}

span.section-info {
  font-size: 12px;
  color: gray;
  display: block;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active-step {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #078888;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #078888;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.active-step .step-counter {
  background-color: #078888;
}

.select-container .mat-form-field {
  width: auto;
}
app-drop-down-header.ng-star-inserted {
  // padding: 11px;
}

span.original-header {
  display: block;
  background-color: #078888;
  padding: 12px 1px;
  font-size: 14px;
}

.upload-step-btn-container {
  display: flex;
  justify-content: flex-end;
}

.mat-raised-button.next-button {
  margin-left: 10px;
}

button.mat-focus-indicator.mat-menu-trigger.additional-actions.mat-icon-button.mat-button-base {
  background-color: red;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.additional-actions span.mat-button-wrapper {
  color: white;
}

.additional-actions {
  margin-right: 8px;
}

span.button-info {
  font-size: 9px;
  color: #808080ab;
  position: relative;
  top: -33px;
  display: block;
}
.action-button-container:not(:last-child) {
  border-bottom: 1px solid #808080ab;
}

.institutions-header-btn-container {
  display: flex;
}

.institutions-header-btn.active-institution-header {
  border-bottom: 3px solid #00c4c4;
}
.institutions-header-btn {
  padding: 10px;
}

.institutions-header-btn:hover {
  cursor: pointer;
  background-color: #00c4c4;
  color: white;
}

span.interest-rates.ng-star-inserted {
  display: block;
}

.interest-rates-container.ng-star-inserted {
  display: flex;
}

span.interest-rate-txt.ng-star-inserted {
  margin-right: 10px;
}

.ironfly-primary-graph {
  display: inline-block;
  width: 100%;
}

// For some reason the mat-menu-item css doesnt follow in
// apps/ironfly-web/src/app/components/drop-down-select-button/drop-down-select-button.component.html
.ironfly-filter-action-button {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
}

// Table

.empty-account-name-container.ng-star-inserted {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

p.insto-name {
  margin: 0;
  margin-top: 0.4rem;
}

.currency-importing-from-insturment mat-progress-bar.mat-progress-bar.mat-primary {
  width: 75%;
}

.currency-update-progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-progress-bar {
  height: 10px !important;
  background-color: #3498db; /* Set the background color */
}

.mat-progress-bar-fill::after {
  background-color: #e74c3c; /* Set the custom progress color */
}

.buttons-overlay {
  width: 100%;
  height: 100%;
}

.buttons-overlay .spinner-container {
  height: 100%;
  position: absolute;
}

mat-form-field.amount-type-container {
  display: flex;
  margin: 1vw auto;
  width: 80vw;
}

p.year-error.ng-star-inserted {
  font-size: 10px;
  color: red;
  font-weight: bold;
  font-family: system-ui;
  margin: 2px 0 0;
}

.year-input {
  border: 1px solid #d0c5c5 !important;
  padding: 7px !important;
}

// fixing model z-index when purging vault
.modal-backdrop {
  z-index: -1 !important;
}

.estimates-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e2e4;
}

i.bwi.bwi-plus.bwi-fw.add-estimate-icon:hover {
  cursor: pointer;
}
i.bwi.bwi-plus.bwi-fw.add-estimate-icon {
  font-weight: bold;
  color: #00c4c4;
  font-size: 16px;
}

#estimate-form-modal label.input-label {
  font-size: 13px;
  font-weight: normal;
  margin-bottom: auto;
}

/*div.estimate-form-parts-container div.part {
  width: 49%;
}*/

.estimate-form-parts-container .table-bordered {
  padding: 6px;
}

/*mat-dialog-container#mat-dialog-0 {
  padding: 0;
}*/

/*
.estimate-form-parts-container {
  display: flex;
  justify-content: space-between;
}
*/

.value-amount-container {
  border: 1px solid #ced4da;
  padding: 10px;
  margin-bottom: 17px;
  border-radius: 4px;
}

.part.recurring-part {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 4px;
}

.recurring-part-item {
  width: 40%;
}

.date-picker-recurring-container {
  display: flex;
  justify-content: space-between;
}

.amount-symbol-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.symbol-container {
  margin-left: 3px;
}

.estimate-form-parts-container {
  width: 70%;
  margin: auto;
}

.estimates-form-dialog .mat-dialog-content {
  margin: 0;
  padding: 0;
}

div#recurring-variable-amount {
  width: 52%;
}
div#amount-container {
  width: 45%;
  min-width: 110px;
}
.amount-type-container {
  display: flex;
  justify-content: space-between;
}

.activeDiv {
  pointer-events: all;
  opacity: 1;
}

.notActiveDiv {
  pointer-events: none;
  opacity: 0.4;
}

.estimate-table-container tr:hover {
  cursor: pointer;
}

.estimate-table-container td {
  color: inherit !important;
  padding-left: 5px !important;
}

.estimate-table-container thead {
  background-color: #078888 !important;
}
.estimate-table-container .mat-header-cell {
  color: white;
}

.estimate-table-container tbody tr:hover {
  cursor: pointer;
  background-color: #00c4c4 !important;
  color: white;
}

.estimates-form-dialog label {
  font-size: 13px;
  font-weight: normal !important;
  margin-bottom: auto;
}

.estimates-form-dialog h1#create-estimate-header {
  text-align: center;
  padding: 5px;
  color: #4f4f4f;
  border-bottom: 1px solid #ced4da;
  width: 350px;
  margin: auto;
}

span.mat-form-field-required-marker {
  font-size: 9px;
  color: red;
  position: relative;
  top: -4px;
}

h1 span.mat-form-field-required-marker {
  display: block;
  font-size: 9px;
}

.categories-container {
  background-color: #fff;
  margin-top: -16px;
  padding: 9px;
  position: absolute;
  width: 45%;
  z-index: 9999;
  box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

.categories-items-container {
  max-height: 200px;
  overflow-y: scroll;
}

.one-category-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8080805e;
  padding: 5px 0;
  margin-bottom: 5px;
}

.one-category-container.account-for-estimate.ng-star-inserted:hover {
  cursor: pointer;
  background-color: #00c4c4;
  padding-left: 5px;
  border-radius: 5px;
  color: white;
}

button.estimate-account-button {
  background-color: #e07c4a;
  border: none;
}

.one-category-container input.form-control {
  width: 40%;
}

.form-group.period-options-container {
  width: 100%;
}

.gloss-btn {
  background: #00c4c4 !important;
  &:hover {
    background: #00c4c4 !important;
  }
}
#preference-cancel-btn {
  margin-left: 5px;
}

.right-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 8px;
  }
}

.dropdown-item:disabled {
  opacity: 0.4;
}

.estimate-table-container nav.navbar.navbar-expand-lg.navbar-light.nav-transactions {
  padding: 0 !important;
  background-color: #fafafa !important;
  margin-bottom: 2px;
}

.navigation-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 0px 21px rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1000;
  width: max-content;
}
.custom-tooltip:before {
  z-index: 10001;
  position: absolute;
  top: 100%;
  left: 60%;
  content: "";
  width: 0;
  height: 0;
  border-top: solid 12px #fff;
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
}

span.tooltip-account-name {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #f1c9c9;
  margin-bottom: 3px;
  color: #4e4e4e;
}
span.symbol-tooltip {
  font-weight: bold;
  color: gray;
}

.tooltip-account:not(:first-child) {
  margin-top: 12px;
}

.tooltip-amount {
  text-align: left;
  font-size: 10px;
}

.balance-container:hover .popup {
  display: block;
}

.info-placehodler {
  z-index: 10000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
}

span.link-span {
  cursor: pointer;
  text-decoration: underline;
  color: cornflowerblue;
}

.import-flow-modal-header {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.parent-label {
  margin-top: -125px;
}

.account-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.origin-icon {
  font-size: 1em;
  color: #00c4c4;
  font-weight: bold;
}

span.info-text {
  padding: 20px;
  font-size: 12px;
  color: gray;
}

a.btn-filter.link-span:hover {
  cursor: pointer;
}
a.btn-filter.link-span {
  font-weight: bold;
}

.db-jcsb-aic-m0-p0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.no-data-container.ng-star-inserted {
  display: flex;
  align-items: center;
  width: 700px;
  margin: auto;
  height: 80%;
}
.image-container {
  width: 200px;
}

h1.big-header {
  font-weight: bold;
  color: gray !important;
}

h3.small-header {
  font-weight: bold;
}

p.text-description {
  color: gray;
  font-size: 13px;
}

.input-search-container {
  display: flex;
  align-items: center;
}
.input-search-container i.bwi.bwi-caret-down.bwi-sm {
  position: absolute;
  right: 27px;
  margin-top: 10px;
  font-size: 11px;
}

span.dataIn {
  color: #13a226;
}

span.dataOut {
  color: #c0392b;
}

span.dataNormalizedIn {
  color: #1382ff;
}
span.dataNormalizedOut {
  color: #d6500d;
}

span.dataBalance {
  font-weight: bold;
}

.ag-root-wrapper {
  font-size: 0.8rem;
}

input[type="checkbox"].transaction-checkbox-input {
  height: 16px;
  line-height: 0;
  margin: auto;
  vertical-align: middle;
  width: 16px;
}

.checked-checkbox {
  background-color: yellow; /* Change to your desired color */
}

._sCheckBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-selector {
  .help-tooltip {
    padding: 8px 16px;
    gap: 8px;
    position: absolute;
    left: 0px;
    top: 0px;

    background: #00c4c4;
    border-radius: 4px;
    z-index: 1000;
    max-width: 40%;

    .tooltip-information {
      font-family: "Montserrat";
      font-style: normal;
      font-size: 15px;
      // line-height: 125%;
      letter-spacing: 0.004em;

      /* Shades/white */
      color: #ffffff;

      .help-heading {
        font-weight: 900;
      }
    }
  }
}
