$newRowBgColor: rgb(198, 239, 206);
$duplicateRowBgColor: rgb(255, 199, 206);

.table-viewport {
  height: 600px;
  overflow: auto;
  table {
    width: 100%;
    table-layout: auto; /* Adjust table layout based on content */
    border-collapse: collapse;
    margin: auto;
    .mat-sort-header-arrow {
      color: #fff !important;
    }
    .mat-row,
    .mat-header-row {
      display: table-row;
      padding: 0;
    }
    .mat-cell,
    .mat-header-cell {
      word-wrap: initial;
      display: table-cell;
      padding: 0;
      line-break: unset;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
    }
    .mat-header-cell {
      background-color: #00c4c4;
      color: #fff;
    }
    .row-new {
      color: rgb(0, 97, 0);
      background-color: rgb(198, 239, 206);
    }
    .row-duplicate {
      color: rgb(156, 0, 6);
      background-color: rgb(255, 199, 206);
    }
  }
}

.arrange-table {
  .table-viewport {
    height: 100%;
  }
}

.color-code-container {
  display: flex;
}

.color-block {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 7px;
  border-radius: 4px;
}

.row-new {
  background-color: $newRowBgColor !important;
}

.row-duplicate {
  background-color: $duplicateRowBgColor !important;
}
