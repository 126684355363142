.flex-input {
  display: flex;
  column-gap: 10px;
}
.conversion-rate {
  display: flex;
  justify-content: space-between;
  .equals-sign {
    text-align: center;
  }
}
.header,
.mat-dialog-actions {
  display: flex;
  justify-content: space-between;
}
.mat-form-field-flex {
  justify-content: space-between;
}

#datepicker-toggle-icon {
  // this is to undo the moved toggle button by ironfly.scss line 37
  position: relative;
  float: right;
  top: 0;
}
