.preview {
  &-nodata {
    text-align: center;
  }
  &-table-wrapper {
    height: 70vh;
    display: flex;
    flex-direction: column;
    .preview-table {
      width: 100%;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      &-header {
        .search-box {
          margin-left: 20px;
          .mat-form-field-wrapper {
            padding: 0 !important;
          }
        }
      }
      &-content {
        padding: 0;
        height: 70%;
      }
      &-actions {
        margin-right: 20px;
      }
    }
  }
}

.fill-available-height {
  flex-grow: 1;
}

.color-codes {
  display: flex !important;
  flex-direction: column;
  padding: 0 5px;
  justify-content: space-around;
}

.color-code-container {
  display: flex;
}

.color-block {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 7px;
  border-radius: 4px;
}

.form-group.mb-4.custom-select {
  margin-bottom: 0 !important;
}
.custom-select .form-control {
  background-color: #c6efce !important;
  color: black !important;
  border: none !important;
}

.preview-table-content .custom-select .mat-select-value-text {
  color: #5b6e5f;
}
